import React, { useState } from "react"
import Layout from "../components/common/layout"
import { graphql } from "gatsby"
import murrineStyles from "../styles/pages/murrine.module.scss"
import MurrinaCard from "../components/cards/murrinaCard"

const Gioielli = ({ data, location }) => {
  const murrine = data.allStrapiMurrine.edges || []

  const [filter, setFilter] = useState("all")

  const handleFilter = (category) => {
    setFilter(category)
  }

  const filteredMurrine = filter === "all" ? murrine : murrine.filter(murrina => (
        murrina.node.nome.toLowerCase().includes(filter.toLowerCase())))

  return (
    <Layout
      pageMeta={{
        title: `Gioielli`,
        keywords: [`gioielli`, `Antica Murrina Venezia`, `Isola Bella`, `Venice Art & Glass`],
        description: `Gioielli in vetro di Murano di Antica Murrina Venezia, Venice Art & Glass e gioielli in argento, oro e smalti di Isola Bella`,
        link: `/gioielli`,
      }}
      location={location}
    >
      <ul className={murrineStyles.filters}>
        <li className={filter === "all" ? `${murrineStyles.filter} ${murrineStyles.active}` : `${murrineStyles.filter}`} onClick={()=>handleFilter("all")}>tutto</li>
        <li className={filter === "anell" ? `${murrineStyles.filter} ${murrineStyles.active}` : `${murrineStyles.filter}`} onClick={()=>handleFilter("anell")}>anelli</li>
        <li className={filter === "orecchin" ? `${murrineStyles.filter} ${murrineStyles.active}` : `${murrineStyles.filter}`} onClick={()=>handleFilter("orecchin")}>orecchini</li>
        <li className={filter === "collan" ? `${murrineStyles.filter} ${murrineStyles.active}` : `${murrineStyles.filter}`} onClick={()=>handleFilter("collan")}>collane</li>
        <li className={filter === "braccial" ? `${murrineStyles.filter} ${murrineStyles.active}` : `${murrineStyles.filter}`} onClick={()=>handleFilter("braccial")}>bracciali</li>
      </ul>
      <ul className={murrineStyles.murrineGioielli}>
        {filteredMurrine.length ? (
          filteredMurrine.map(murrina => (
            <MurrinaCard key={murrina.node.strapiId} murrina={murrina} />
          ))
        ) : (
          <p className={murrineStyles.text}>
            non ci sono prodotti in questa categoria
          </p>
        )}
      </ul>
    </Layout>
  )
}
export default Gioielli

export const query = graphql`
  query GioielliQuery {
    allStrapiMurrine(filter: {categoria: {eq: "gioielli"}}) {
      edges {
        node {
          strapiId
          slug
          nome
          categoria
          prezzo
          promo {
            codice
            inizio
            fine
          }
          marchio {
            nome
            logo {
              alternativeText
              hash
              width
              height
              url
            }
          }
          variante_colore {
            colore
            quantita
            foto {
              alternativeText
              hash
              width
              height
              url
            }
          }
        }
      }
    }
  }
`